@import "forms/labels";
@import "forms/form-text";
@import "forms/form-control";
@import "forms/form-select";
@import "forms/form-check";
@import "forms/form-range";
@import "forms/floating-labels";
@import "forms/input-group";
@import "forms/validation";

form {
	.field {
		label.form-label {
			font-size: 0.9em;
			display: block;
			margin-bottom: 2px;
		}
	}
}