@charset "UTF-8";

@font-face {
  font-family: "site-icons";
  src:
    url("/fonts/site_icons.woff2") format("woff2"),
    url("/fonts/site_icons.woff") format("woff"),
    url("/fonts/site_icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "site-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "site-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\64";
}
.icon-rss:before {
  content: "\69";
}
.icon-right-big:before {
  content: "\6f";
}
.icon-left-big:before {
  content: "\72";
}
.icon-down-big:before {
  content: "\76";
}
.icon-menu:before {
  content: "\78";
}
.icon-up-big:before {
  content: "\7a";
}
.icon-search:before {
  content: "\41";
}
.icon-menu-2:before {
  content: "\71";
}
.icon-youtube:before {
  content: "\4b";
}
.icon-facebook:before {
  content: "\4d";
}
.icon-linkedin-alt:before {
  content: "\4e";
}
.icon-linkedin:before {
  content: "\4f";
}
.icon-vimeo:before {
  content: "\50";
}
.icon-pinterest:before {
  content: "\62";
}
.icon-menu-3:before {
  content: "\68";
}
.icon-close:before {
  content: "\6b";
}
.icon-menu-4:before {
  content: "\58";
}
.icon-trash:before {
  content: "\34";
}
.icon-menu-5:before {
  content: "\22";
}
.icon-google:before {
  content: "\51";
}
.icon-google-plus:before {
  content: "\24";
}
.icon-outline-instagram:before {
  content: "\27";
}
.icon-outline-linkedin:before {
  content: "\28";
}
.icon-outline-rss:before {
  content: "\29";
}
.icon-outline-facebook:before {
  content: "\2a";
}
.icon-outline-google-plus:before {
  content: "\2f";
}
.icon-outline-twitter:before {
  content: "\3a";
}
.icon-outline-vimeo:before {
  content: "\3b";
}
.icon-outline-pinterest:before {
  content: "\3d";
}
.icon-outline-email:before {
  content: "\42";
}
.icon-youtube-alt:before {
  content: "\61";
}
.icon-mail:before {
  content: "\65";
}
.icon-home:before {
  content: "\6c";
}
.icon-cart:before {
  content: "\6d";
}
.icon-phone:before {
  content: "\6e";
}
.icon-location:before {
  content: "\74";
}
.icon-pdf:before {
  content: "\43";
}
.icon-nine-menu:before {
  content: "\4a";
}
.icon-marker:before {
  content: "\4c";
}
.icon-ruby:before {
  content: "\52";
}
.icon-blog:before {
  content: "\53";
}
.icon-client:before {
  content: "\54";
}
.icon-message:before {
  content: "\55";
}
.icon-n:before {
  content: "\56";
}
.icon-video:before {
  content: "\57";
}
.icon-user:before {
  content: "\5a";
}
.icon-globe:before {
  content: "\30";
}
.icon-outline-youtube:before {
  content: "\31";
}
.icon-external:before {
  content: "\66";
}
.icon-phone-2:before {
  content: "\67";
}
.icon-pending:before {
  content: "\70";
}
.icon-check:before {
  content: "\73";
}
.icon-ticket:before {
  content: "\77";
}
.icon-instagram:before {
  content: "\79";
}
.icon-lock:before {
  content: "\32";
}
.icon-houzz:before {
  content: "\33";
}
.icon-circle-facebook:before {
  content: "\63";
}
.icon-circle-instagram:before {
  content: "\6a";
}
.icon-circle-vimeo:before {
  content: "\44";
}
.icon-circle-twitter:before {
  content: "\45";
}
.icon-circle-google-plus:before {
  content: "\46";
}
.icon-circle-linkedin:before {
  content: "\47";
}
.icon-circle-youtube:before {
  content: "\48";
}
.icon-circle-rss:before {
  content: "\49";
}
.icon-circle-soundcloud:before {
  content: "\59";
}
.icon-circle-pinterest:before {
  content: "\35";
}
.icon-circle-spotify:before {
  content: "\36";
}
.icon-circle-mail:before {
  content: "\75";
}