.banner {
	position: relative;
	padding: 40px 20px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	.overlay {
		background-color: rgba($primary, 0.35);
		z-index: 1;
	}
	.content {
		position: relative;
		z-index: 2;
		color: #fff;
		.btn {
			margin-top: 20px;
		}
	}
	&.video {
		.video-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 0;
      video {
        display: block;
        position: absolute;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -51%);
        object-fit: cover;
        height: 101%;
        width: 101%;
      }
    }
	}
	&.link {
		height: 100%;
		min-height: 400px;
		text-decoration: none;
		@include media-breakpoint-down(md) {
			height: 100%;
			min-height: none;
		}
		.content {
			padding: 70px 20px 20px 20px;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			@include media-breakpoint-down(md) {
				padding: 60px 20px;
			}
			h4 {
				span.icon-right-big {
					display: inline-block;
					margin: 0 0 0 5px;
					font-size: 18px;
					height: 20px;
					position: relative;
					top: 2px;
				}
			}
			p {

			}
		}
		&.sml {
			padding: 0;
			align-items: center;
			.content {
				padding: 15px;
				height: auto;
				min-height: 140px;
				p {
					margin-bottom: 0;
				}
			}
		}
		&.square {
			padding: 0 0 100% 0;
			@include media-breakpoint-down(md) {
				padding: 0 0 200px 0;
				min-height: 200px;
			}
			span.bottom-right {
				position: absolute;
				bottom: 0;
				right: 0;
				background-color: rgba(0,0,0,0.7);
				color: $white;
				padding: 12px 20px 10px 20px;
				&::after {
					content: " ";
					display: block;
					width: 100%;
					width: calc(100% - 34px);
					position: absolute;
					left: 17px;
					bottom: 8px;
					height: 1px;
					background: $white;
				}
				&.underline-secondary::after {
					background-color: $secondary;
				}
				&.underline-primary::after {
					background-color: $primary;
				}
				&.underline-yellow::after {
					background-color: $yellow;
				}
				&.underline-red::after {
					background-color: $red;
				}
			}
			&:hover span.bottom-right.underline-secondary {
				color: $secondary;
			}
			&:hover span.bottom-right.underline-primary {
				color: $primary;
			}
			&:hover span.bottom-right.underline-yellow {
				color: $yellow;
			}
			&:hover span.bottom-right.underline-red {
				color: $red;
			}
		}
		.overlay {
			transition: background-color 300ms ease;
			background-color: rgba(0,0,0, 0.7);
		}
		&:hover .overlay.hover-yellow {
			background-color: rgba($yellow, 0.7);
		}
		&:hover .overlay.hover-red {
			background-color: rgba($red, 0.7);
		}
		&:hover .overlay.hover-green {
			background-color: rgba($green, 0.7);
		}
		&:hover .overlay.hover-blue {
			background-color: rgba($blue, 0.7);
		}
	}
}