.google-map {
	height: 400px;
	width: 100%;
	img {
		max-width: none !important;
	}
	.info-pop {
		text-align: center;
		h3 {
			font-size: 1.1em;
			margin-bottom: 6px;
		}
		.btn.small {
			margin-top: 8px;
		}
	}
}

.store-locations {
	.overlay {
		background-color: rgba(#2C2C2C, 0.91);
	}
	.row {
		position: relative;
		z-index: 3;
		h3 {
			font-size: 2.4em;
		}
		form {
			margin-top: 20px;
			input {
				background: transparent;
				border: 1px solid #fff;
				color: #fff;
				&:focus {
					outline: 0;
					box-shadow: none;
					border-color: $yellow;
				}
			}
			.btn {
				margin-top: 10px;
			}
		}
	}
}