//
// Headings
//
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}

.text-hover-black:hover {
  color: $black !important;
}

.text-lblue {
  color: #003366;
}

h1, h2, h3, h4, h5, h6 {
  &.lined {
    position: relative;
    &::before {
      content: " ";
      display: block;
      width: 100%;
      right: 0;
      top: 50%;
      height: 1px;
      background: $black;
      position: absolute;
      z-index: 1;
    }
    span {
      background: $white;
      display: inline-block;
      padding: 0 10px;
      position: relative;
      z-index: 2;
    }
    &.left span {
      padding: 0 10px 0 0;
    }
  }
  &.white-lined {
    position: relative;
    &::before {
      content: " ";
      display: block;
      width: 100%;
      right: 0;
      top: 50%;
      height: 1px;
      background: $white;
      position: absolute;
      z-index: 1;
    }
    span {
      background: $black;
      display: inline-block;
      padding: 0 10px;
      position: relative;
      z-index: 2;
    }
    &.left span {
      padding: 0 10px 0 0;
    }
  }
}

// .text-yellow {
//   color: $yellow;
// }

.text-black {
  color: $black;
}

.text-hover-primary:hover {
  color: $primary !important;
}

.text-hover-secondary:hover {
  color: $secondary !important;
}

.text-hover-red:hover {
  color: $red !important;
}

.text-hover-yellow:hover {
  color: $yellow !important;
}


.richtext {
  p:last-of-type {
    margin-bottom: 0;
  }
  p, ul {
    line-height: 1.7em;
  }
}

.fw-light b,
.fw-light strong {
  font-weight: 700;
}

.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    @include font-size($font-size);
    font-weight: $display-font-weight;
    line-height: $display-line-height;
  }
}

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-font-size);

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y;
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-footer-font-size);
  color: $blockquote-footer-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
