$blue: 					#0A6AA1;
$green: 				#00A261;
$red: 					#E51937;
$yellow: 				#FDB813;

$primary: 			$green;
$secondary: 		$blue;

$link-color: 		$green;

$font-family-sans-serif:  "forma-djr-text";
$font-main:               300 1em $font-family-sans-serif, sans-serif;