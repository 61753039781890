.footer-wrapper {
  overflow: hidden;
  clear: both;
  .ao-form-wrap {
    border-top: 1px solid $gray-300;
  }
  form.ao-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: stretch;
    }
    .ao-row:nth-of-type(1) {
      flex: 1;
      gap: 20px;
      @include media-breakpoint-down(md) {
        margin: 0 0 20px 0;
      }
      .ao-column .ao-column-inner {
        padding: 0 !important;
      }
    }
    .ao-optin-block {
      margin-top: 20px;
    }
    #row-r1717440815800.ao-row {
      padding: 0 !important;
    }
    .ao-row:nth-of-type(2) {
      flex: 1;
      margin-right: 20px;
      @include media-breakpoint-down(md) {
        margin: 0 0 20px 0;
      }
      .ao-column .ao-column-inner {
        padding: 0 !important;
      }
    }
    .ao-row:nth-of-type(3) {
      flex: 0 1 auto;
    }
  }
  footer.main {
    background-color: #222222;
    background-image: url('/images/footer_bg.png');
    background-size: auto 100%;
    background-position: 0 -40px;
    background-repeat: no-repeat;
    color: $white;
    font-size: 15px;
    .nav-hold {
      max-width: 650px;
      display: flex;
      margin: 0 0 0 auto;
      align-items: center;
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    nav {
      flex: 1;
      &.big {
        font-size: 1.2em;
      }
      &.small {
        ul {
          li {
            a {
              color: rgba(255,255,255,0.7);
              &:hover {
                color: $yellow;
              }
            }
          }
        }
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          display: block;
        }
        &.small-links {
          justify-content: flex-start;
          li {
            margin-right: 10px;
            &:last-of-type {
              margin-right: 0;
            }
          }
          a {
            font-size: 13px;
            color: rgba(255,255,255,0.7);
            &:hover {
              color: $yellow;
            }
          }
        }
        li {
          display: block;
          @include media-breakpoint-down(md) {
            margin: 0 0 10px 0;
          }
          a {
            color: $white;
            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }
    ul.social-links {
      margin-left: 2.5vw;
      position: relative;
      top: 3px;
      flex: 0 0 148px;
      width: 148px;
      @include media-breakpoint-down(md) {
        flex: 0 1 auto;
        top: 4px;
        right: 5px;
        margin: 0;
      }
      li {
        a {
          color: $yellow;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .copyright-bar {
    height: 40px;
    @include media-breakpoint-down(md) {
      height: auto;
      padding: 6px 10px;
    }
    .container {
      height: 100%;
      font-size: 12px;
      p {
        margin: 0;
        flex: 1;
      }
      @include media-breakpoint-down(md) {
        display: block !important;
        text-align: center;
      }
    }
    .nine {
      text-align: right;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        justify-content: center;
        text-align: center;
        margin-top: 5px;
      }
      a {
        display: block;
        &:hover {
          .nine-logo-svg { fill: $primary; }
          .nine-logo-svg polygon { stroke: $primary; }
        }
      }
      span {
        display: block;
        margin: 0 5px 0 0;
      }
      .nine-logo-svg {
        width: 50px;
        height: 25px;
        fill: $black;
        polygon {
          stroke: $black;
        }
      }
    }
  }
}