.blog-list {
	.col-sm-3 {
		border-left: 1px solid $gray-600;
		@include media-breakpoint-down(md) {
			border: 0;
		}
		ul.list-unstyled {
			li {
				a {
					color: $black;
					&:hover {
						color: $primary;
					}
				}
			}
		}
	}
	.post {
		border-bottom: 1px solid $gray-600;
		.image.bg-cover {
			background-image: url('/images/banner.jpg');
		}
		.col-sm-8 {
			@include media-breakpoint-up(md) {
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		    align-items: flex-start;
			}
		}
	}
}

.post-details-wrapper {
	.categories-list {
		display: flex;
		flex-wrap: wrap;
		li {
			flex: 0 1 auto;
			padding: 0 10px 0 0;
			border-right: 1px solid $gray-400;
			margin: 0 12px 10px 0;
			line-height: 20px;
			&:last-of-type {
				border-right: 0;
			}
			a {
				color: $gray-900;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}