.subpage-banner {
	position: relative;
	padding: 60px 0 10px 0;
	background-image: url('/images/banner.jpg');
	background-size: cover;
	background-position: center;
	&.drainage {
		padding: 25vw 0 40px 0;
	}
	h1.title {
		text-transform: uppercase;
		margin-bottom: 0;
		&.store {
			font-size: 2.4rem;
			span.title {
				display: block;
				margin-bottom: 2px;
			}
			span.subtitle {
				display: block;
				font-size: 1.5rem;
			}
		}
	}
	.banner-text {
		margin-top: 20px;
		&::after {
			content: '';
			display: block;
			width: 100px;
			margin-top: 30px;
			border-bottom: 2px solid #fff;
		}
		@include media-breakpoint-up(md) {
			max-width: 71%;
		}
	}
	h5.date {
		margin: 0;
	}
	.overlay {
		background-color: rgba(0,0,0,0.7);
	}
	.container {
		position: relative;
		z-index: 3;
	}
	ol.breadcrumb {
		margin-top: 40px;
		margin-bottom: 0;
		li.breadcrumb-item {
			&.active {
				color: $gray-400;
			}
			a {
				color: #fff;
				text-decoration: none;
				&:hover {
					color: $yellow;
				}
			}
		}
	}
}

.product-lead-form {
	input.form-control,
	select.form-control {
		border: 0;
	}
}

.photo-gallery {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.photo {
		flex: 0 0 32.5%;
		margin: 0 0 14px 0;
		@include media-breakpoint-down(md) {
			flex: 0 0 48%;
		}
		a.photo-image {
			display: block;
			height: 220px;
			background-size: cover;
			background-position: center;
			text-decoration: none;
			transition: opacity 400ms ease;
			&:hover {
				opacity: 0.8;
			}
			@include media-breakpoint-down(md) {
				height: 180px;
			}
		}
	}
}

.iframe-holder {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 2%;
  text-align: center;
  iframe {
    display: block;
    height: 600px;
    width: 100%;
  }
  &.video {
  	padding: 0 0 56.25% 0;
  	position: relative;
  	max-width: none;
  	iframe {
  		position: absolute;
  		left: 0;
  		top: 0;
  		width: 100%;
  		height: 100%;
  	}
  }
}

.featured-product {
	.row {
		.col-sm-6:first-of-type {
			border-right: 1px solid #707070;
			@include media-breakpoint-down(md) {
				border: 0;
			}
			img {
				width: auto;
				height: auto;
				margin: 0 auto;
				max-width: 360px;
				max-height: 300px;
			}
		}
	}
}

.product-category-grid {
	.row {
		@include media-breakpoint-down(md) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

.product-details {
	img.main-image {
		display: block;
		width: 100%;
		height: 500px;
		margin: 0;
		object-fit: contain;
		object-position: center;
		@include media-breakpoint-down(sm) {
			height: 350px;
		}
	}
	.iframe-holder {
		position: relative;
		width: 100%;
		display: none;
		height: 500px;
		@include media-breakpoint-down(sm) {
			height: 350px;
		}
		iframe {
			display: block;
			border: 0;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	ul.thumbnail-images {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		padding: 0;
		margin: 20px 0 0 0;
		justify-content: center;
		width: 100%;
		&.color-yellow li a.active {
			border-color: $yellow;
		}
		&.color-red li a.active {
			border-color: $red;
		}
		&.color-green li a.active {
			border-color: $green;
		}
		&.color-blue li a.active {
			border-color: $blue;
		}
		li {
			flex: 0 0 90px;
			display: block;
			margin: 0 5px 10px 5px;
			@include media-breakpoint-down(sm) {
				flex: 0 0 70px;
			}
			a {
				display: block;
				text-decoration: none;
				border: 5px solid transparent;
				&.active {
					border-color: $green;
				}
				&:hover {
					opacity: 0.7;
				}
				img {
					display: block;
					width: 80px;
					height: 80px;
					object-fit: contain;
					object-position: center;
					@include media-breakpoint-down(sm) {
						width: 60px;
						height: 60px;
					}
				}
			}
		}
	}
	.row {
		.col:first-of-type {
			img {
				width: auto;
				height: auto;
				margin: 0 auto;
				max-width: 100%;
				max-height: none;
			}
		}
	}
}

.product-long-description {
	h6 {
		margin-top: 20px;
	}
	table {
		width: 100%;
		border: 1px solid $gray-400;
		tr {
			border-bottom: 1px solid $gray-400;
			&:last-of-type {
				border-bottom: 0;
			}
			td {
				padding: 10px 10px 9px 10px;
				&:first-of-type {
					font-weight: bold;
				}
			}
		}
	}
}

.product-grid {
	.col-sm-3 .inner {
		border-right: 1px solid $gray-600;
		padding-bottom: 100px;
		@include media-breakpoint-down(md) {
			border: 0;
			padding-bottom: 0;
		}
	}
	.product {
		border: 1px solid $gray-400;
		flex: 0 0 31%;
		text-align: center;
		& > a {
			text-decoration: none;
			display: block;
			color: $black;
			&:hover h4 {
				color: $primary;
			}
		}
		img {
			height: 180px;
			width: 100%;
			object-fit: contain;
		}
		h4 {
			font-size: 1.1em;
		}
		h5 {
			font-size: 1em;
			color: $gray-700;
			font-weight: normal;
		}
		@include media-breakpoint-down(md) {
			flex: 0 0 48%;
		}
	}
}

.featured-products-grid {
	h3 {
		margin-bottom: 2rem;
	}
	.product-grid {
		flex-wrap: wrap;
		justify-content: space-between;
		.product {
			border: 1px solid $gray-400;
			flex: 0 0 49%;
			text-align: center;
			margin: 0 0 1rem 0;
			& > a {
				text-decoration: none;
				display: block;
				color: $black;
				&:hover h4 {
					color: $primary;
				}
			}
			img {
				height: 180px;
				width: 100%;
				object-fit: contain;
			}
			h4 {
				font-size: 1.1em;
			}
			h5 {
				font-size: 1em;
				color: $gray-700;
				font-weight: normal;
			}
			@include media-breakpoint-down(md) {
				flex: 0 0 48%;
			}
		}
	}
}

.category-grid {
	.col-sm-3 .inner {
		border-right: 1px solid $gray-600;
		padding-bottom: 100px;
		@include media-breakpoint-down(md) {
			border: 0;
			padding-bottom: 0;
		}
	}
	.category {
		border: 1px solid $gray-400;
		flex: 0 0 31%;
		& > a {
			text-decoration: none;
			display: block;
			&:hover p {
				color: $primary;
			}
		}
		img {
			height: 180px;
			width: 100%;
			object-fit: contain;
			margin: 0 0 20px 0;
		}
		p {
			font-size: 1.1em;
			line-height: 1.1em;
			font-weight: bold;
			color: $black;
			margin: 0;
			text-align: center;
		}
		@include media-breakpoint-down(md) {
			flex: 0 0 48%;
		}
	}
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

  .grid-item {
    width: 100%;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.landscape-heading {
	position: relative;
	display: flex;
	justify-content: center;

	h2 {
		background-color: #fff;
		padding-inline: 1rem;
		display: inline-block;
		position: relative;
		z-index: 2;
		margin-bottom: 0;
	}

	hr {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}

.landscape-subheading {
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 2px;
	margin-bottom: 1rem;
}