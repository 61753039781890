.delay-show {
  display: none;
}

#mmenu {
  text-align: left; 
  color: $white;
  background: $primary;
  .mm-navbar {
    background: $primary;
  }
  .mm-panels {
    background: $primary;
    .mm-panel {
      background: $primary;
    }
  }
  a, span {
    font-weight: 400;
    color: $white;
  }
}

.cmn-toggle-switch {
  display: none;
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 34px;
  height: 35px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  z-index: 2;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.cmn-toggle-switch:focus {
  outline: none;
}

.cmn-toggle-switch span {
  display: block;
  position: absolute;
  top: 19px;
  left: 0;
  right: 0;
  height: 4px;
  background: $white;
}

.cmn-toggle-switch span::before,
.cmn-toggle-switch span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $white;
  content: "";
}

.cmn-toggle-switch span::before {
  top: -10px;
}

.cmn-toggle-switch span::after {
  bottom: -10px;
}

.cmn-toggle-switch__htx {
  background-color: transparent;
}

.cmn-toggle-switch__htx span {
  -webkit-transition: background 0 0.3s;
          transition: background 0 0.3s;
}

.cmn-toggle-switch__htx span::before,
.cmn-toggle-switch__htx span::after {
  -webkit-transition-duration: 0.3s, 0.3s;
          transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0;
          transition-delay: 0.3s, 0;
}

.cmn-toggle-switch__htx span::before {
  -webkit-transition-property: top, -webkit-transform;
          transition-property: top, transform;
}

.cmn-toggle-switch__htx span::after {
  -webkit-transition-property: bottom, -webkit-transform;
          transition-property: bottom, transform;
}

.mm-opening .cmn-toggle-switch__htx span {
  background: transparent;
}

.mm-opening .cmn-toggle-switch__htx span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.mm-opening .cmn-toggle-switch__htx span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.mm-opening .cmn-toggle-switch__htx span::before,
.mm-opening .cmn-toggle-switch__htx span::after {
  -webkit-transition-delay: 0, 0.3s;
          transition-delay: 0, 0.3s;
}

.mm-menu .mm-navbar > a {
  color: $white !important;
}

.mm-btn::after, .mm-btn::before {
  border-color: $white !important;
}

.mm-listitem__btn {
  border-color: rgba($white, 0.4) !important;
}

.mm-menu .mm-listview > li:after, .mm-menu .mm-listview > li .mm-prev:after, .mm-menu .mm-listview > li .mm-next:before, .mm-menu .mm-navbar {
  border-color: rgba($white, 0.4) !important;
}