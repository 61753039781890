$height: 450px;
$mobile_height: 360px;

.big-slider {
	height: $height;
	@include media-breakpoint-down(md) { height: $mobile_height; }
	.slick-arrow {
		position: absolute;
		background: $white;
		color: $black;
		font-size: 20px;
		width: 50px;
		height: 40px;
		z-index: 5;
		top: calc(50% - 20px);
		padding: 9px 5px 0 5px;
		cursor: pointer;
		@include media-breakpoint-down(md) { 
			width: 40px;
			height: 30px;
			top: calc(50% - 15px);
			font-size: 17px;
			padding-top: 6px;
		}
		&:hover {
			background: $yellow;
		}
		&.slick-prev {
			left: 0;
		}
		&.slick-next {
			right: 0;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		height: $height;
		@include media-breakpoint-down(md) { height: $mobile_height; }
		.slick-track {
			height: $height;
			@include media-breakpoint-down(md) { height: $mobile_height; }
		}
		li.slick-slide {
			position: relative;
			color: $white;
			height: 100%;
			background-size: cover;
			background-position: center;
			.overlay {
				background: rgba(0,0,0,0.7);
			}
			.container {
				position: relative;
				z-index: 2;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				.inner {
					@media all and (max-width: 1240px) {
						padding: 0 40px;
					}
				}
				h2 {
					margin: 0;
					font-size: 2.9vw;
					letter-spacing: 2px;
					font-weight: 300;
					strong {
						font-size: 1.1em;
						font-weight: 700;
						display: block;
					}
					@include media-breakpoint-down(md) {
						font-size: 1.8em;
					}
				}
				.btn {
					margin-top: 1em;
					text-transform: uppercase;
				}
			}
		}
	}
}