body * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-container {
  padding-top: 130px;
  @include media-breakpoint-down(md) {
    padding-top: 60px;
  }
}

ul.social-links {
  list-style-type: none;
  padding: 0; 
  margin: 0; 
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    display: block;
    flex: 0 1 auto;
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
    a {
      text-decoration: none;
      font-size: 24px;
      cursor: pointer;
      &.white {
        color: #fff; 
        &:hover {
          color: $secondary;
        }
      }
      &.colored {
        color: $primary;
        &:hover {
          color: shade($secondary);
        }
      }
    }
  }
}

ul.no-bullet {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(10,10,10,0.3);
  z-index: 0;
}

.state-picker {
	.unit {
		flex: 0 0 5%;
    min-width: 8%;
    text-align: center;
    @include media-breakpoint-down(lg) {
      flex: 0 0 17%;
    }
		@include media-breakpoint-down(md) {
			flex: 0 0 25%;
		}
		@include media-breakpoint-down(sm) {
			flex: 0 0 50%;
		}
    a {
      text-decoration: none;
      color: white;
      &:hover {
        color: #A9A9A9;
      }
    }
	}
}

.hover-pointer:hover {
  cursor: pointer;
}

#ot-sdk-btn {
  color: #fff !important;
  background-color: #00A261 !important;

  &:hover {
    background-color: #00824e !important;
  }
}