$header_height: 80px;
$font_color: $black;

header.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  z-index: 100;
  @include media-breakpoint-down(md) {
    height: 60px;
  }
  .top {
    background: #2D2D2D;
    color: $white;
    height: 90px;
    @include media-breakpoint-down(md) {
      height: 60px;
    }
    .container {
      height: 100%;
      max-width: 1140px;
      padding-right: var(--bs-gutter-x, 1rem);
      padding-left: var(--bs-gutter-x, 1rem);
      justify-content: space-between;
      gap: 20px;
      .logo-link {
        text-decoration: none; 
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        img {
          display: block;
        }
        &:focus {
          outline-width: 0;
        }
        ul.items {
          margin: 0 0 0 15px;
          padding: 0;
          li {
            display: block;
            margin-bottom: 2px;
            border-left: 2px solid $primary;
            font-size: 11px;
            line-height: 11px;
            padding: 2px 0 0 4px;
            color: $white;
            &#blue { border-color: $blue; }
            &#yellow { border-color: $yellow; }
            &#red { border-color: $red; }
            &:last-of-type {
              margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
              font-size: 10px;
              line-height: 9px;
            }
          }
        }
      }
      .right {
        flex: 1;
        nav {
          ul {
            list-style-type: none;
            display: flex;
            margin: 0;
            padding: 0;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            li {
              display: block;
              a {
                text-decoration: none;
                color: #C3B59B;
                font-size: 14px;
                padding: 0;
                display: block;
                &:hover {
                  color: $white;
                }
                @media all and (min-width: 768px) and (max-width: 860px) {
                  font-size: 12px;
                }
              }
            }
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .search-btns {
          display: flex;
          gap: 30px;
          margin-top: 10px;
          @include media-breakpoint-down(md) {
            margin: 0;
          }
          form.search {
            flex: 1;
            position: relative;
            a.icon-search {
              width: 32px;
              height: 32px;
              background-color: #C3B59B;
              display: block;
              color: $white;
              text-decoration: none;
              font-size: 24px;
              padding-top: 3px;
              position: absolute;
              text-align: center;
              right: 0;
              top: 0;
              z-index: 2;
              &:hover {
                background-color: $yellow;
              }
            }
            input.form-control {
              height: 32px;
              background: $white;
              border: 0;
              font-size: 15px;
              padding: 5px 36px 4px 8px;
              color: #2D2D2D;
              &:focus {
                outline: 0;
                box-shadow: none;
              }
            }
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
          #menu-icon {
            right: var(--bs-gutter-x, 1rem);
            top: 9px;
          }
          .btn.btn-yellow {
            flex: 0 1 auto;
            background-color: #C3B59B;
            color: #2D2D2D;
            border: 0;
            height: 32px;
            font-size: 15px;
            line-height: 32px;
            padding: 0 12px;
            &:hover {
              background-color: $yellow;
            }
            @media all and (max-width: 440px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .bottom {
    background: #6D6E72;
    color: #2D2D2D;
    height: 40px;
    position: relative;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .container {
      height: 100%;
      max-width: 1140px;
      padding-right: var(--bs-gutter-x, 1rem);
      padding-left: var(--bs-gutter-x, 1rem);
      nav {
        flex: 1;
        text-align: center;
        height: 100%;
        padding: 0;
        & > ul {
          list-style-type: none;
          display: flex;
          margin: 0;
          padding: 0;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          & > li {
            display: flex;
            align-items: center;
            flex: 0 1 auto;
            position: relative;
            height: 100%;
            & > a, & > span {
              text-decoration: none;
              color: $white;
              font-size: 15px;
              padding: 0;
              display: block;
              &:hover {
                color: $yellow;
              }
            }
            &:hover > span {
              color: $yellow;
            }
            &:hover > ul {
              display: block;
            }
            & > ul {
              display: none;
              position: absolute;
              width: 200px;
              right: 0;
              top: 100%;
              z-index: 2;
              background: #6D6E72;
              list-style-type: none;
              margin: 0;
              padding: 0;
              &.left {
                left: -10px;
                right: auto;
                li {
                  a {
                    text-align: left;
                  }
                }
              }
              li {
                display: block;
                a {
                  display: block;
                  padding: 10px;
                  text-align: right;
                  font-size: 15px;
                  text-decoration: none;
                  color: #fff;
                  &:hover {
                    color: $yellow;
                  }
                  &.icon-external {
                    position: relative;
                    padding-right: 16px;
                    &::before {
                      position: absolute;
                      right: 10px;
                      top: 15px;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}